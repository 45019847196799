<template>
    <div class="story">
      <Video :list="videoList" />
	   <Pagination :page="pageNum" :pageSize="pageSize" :total="total" @handleList="handleList" />
    </div>
</template>

<script>
import Video from '../components/video.vue'
import Pagination from '../components/pagination.vue'
export default {
    data() {
        return {
			total:null,
      pageNum:1,
      pageSize:6,
      videoList:[]
		};
    },
	components:{
		Video,
		Pagination
	},
    mounted() {
      this.handleVideo()
    },
    methods: {
            async handleVideo(){
        const res=await this.request('/img/show',{params:{
          imgType: 'video',
          pageNum:this.pageNum,
          pageSize:this.pageSize
          }})
        if(res.status==200){
            this.videoList=res.data.list
            this.total=res.data.total
        }
    },
      handleList(e){
        this.pageNum=e
        this.handleVideo()
      }
    }
};
</script>

<style scoped lang='less'>


</style>