<template>
    <div class="list">
        <div
            class="item"
            v-for="(item,index) in list"
            :key="index"
            :style="{ 'margin-right': (index + 1) % 3 == 0 ? '0' : '' }"
        >
            <div class="img">
                <video focus="outline" :src="'/gsmm/api/v1/statics/'+item.img_src" controls  />
                <div class="text">{{item.description}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: Array
    },
    data() {
        return {
          
        };
    },
    created() {

    },
    mounted() {

    },
    methods: {
    }
};
</script>

<style scoped lang='less'>
.list {
    width: 100%;
    // background: red;
    display: flex;
    flex-wrap: wrap;
    .item {
        width: 31%;
        height: 238px;
        background: #fff;
        margin-right: 3.5%;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding:20px 20px 10px 20px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.2) ;
        .img {
            width: 100%;
             height: calc(100% - 40px);
            video {
                width: 100%;
                height: 100%;
            }
            video:focus {
 outline: -webkit-focus-ring-color auto 0px;
}
            .text{
                // position: absolute;
                // bottom: 20px;
                width: 100%;
                color: #aaa;
                text-align: center;
            }
        }
    }
}
</style>
